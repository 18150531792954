<template>
  <!-- eslint-disable max-len -->
  <div id="all-svg">
    <svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="dashboard-icon" viewBox="0 0 30 30">
        <path
          d="M12.8333 0.5V16.1667H0.5V0.5H12.8333ZM12.8333 20.5V29.5H0.5V20.5H12.8333ZM29.5 13.8333V29.5H17.1667V13.8333H29.5ZM29.5 9.5H17.1667V0.5H29.5V9.5Z"
        />
      </symbol>
      <symbol id="github-icon" viewBox="0 0 28 28">
        <g clip-path="url(#clip0)">
        <path d="M14 0.583496C6.265 0.583496 0 6.7435 0 14.3408C0 20.4203 4.011 25.5758 9.5725 27.3935C10.2725 27.523 10.5292 27.0972 10.5292 26.732C10.5292 26.4053 10.5175 25.5397 10.5117 24.3928C6.61733 25.2223 5.796 22.5472 5.796 22.5472C5.159 20.9593 4.2385 20.5347 4.2385 20.5347C2.97033 19.6818 4.3365 19.6993 4.3365 19.6993C5.74233 19.795 6.48083 21.1168 6.48083 21.1168C7.72917 23.2203 9.758 22.6125 10.5583 22.2613C10.6843 21.3712 11.0448 20.7657 11.445 20.4215C8.33583 20.0773 5.068 18.8943 5.068 13.6233C5.068 12.1218 5.6105 10.8945 6.50883 9.932C6.35133 9.58433 5.87883 8.1855 6.63133 6.29083C6.63133 6.29083 7.80383 5.92216 10.4813 7.70133C11.6013 7.39566 12.7913 7.244 13.9813 7.237C15.1713 7.244 16.3613 7.39566 17.4813 7.70133C20.1413 5.92216 21.3138 6.29083 21.3138 6.29083C22.0663 8.1855 21.5938 9.58433 21.4538 9.932C22.3463 10.8945 22.8888 12.1218 22.8888 13.6233C22.8888 18.9083 19.6163 20.0715 16.5013 20.4098C16.9913 20.8228 17.4463 21.6663 17.4463 22.9555C17.4463 24.7965 17.4288 26.2758 17.4288 26.7227C17.4288 27.0832 17.6738 27.5137 18.3913 27.376C23.9925 25.57 28 20.411 28 14.3408C28 6.7435 21.7315 0.583496 14 0.583496Z" fill="#121212"/>
        </g>
        <defs>
          <clipPath id="clip0">
          <rect width="28" height="28" fill="white"/>
          </clipPath>
        </defs>
      </symbol>
      <symbol id="google-icon" viewBox="0 0 28 28">
        <path d="M6.5625 14C6.5625 12.6127 6.94471 11.3132 7.60873 10.2004V5.48047H2.88876C1.01544 7.91341 0 10.8785 0 14C0 17.1214 1.01544 20.0865 2.88876 22.5195H7.60873V17.7995C6.94471 16.6868 6.5625 15.3872 6.5625 14Z" fill="#FBBD00"/>
        <path d="M14 21.4377L10.7188 24.7189L14 28.0002C17.1215 28.0002 20.0866 26.9847 22.5195 25.1114V20.3965H17.8046C16.6821 21.0629 15.3771 21.4377 14 21.4377Z" fill="#0F9D58"/>
        <path d="M7.60864 17.7993L2.88867 22.5193C3.25956 23.001 3.66343 23.4623 4.10044 23.8993C6.74469 26.5435 10.2604 27.9998 13.9999 27.9998V21.4373C11.2861 21.4373 8.90758 19.976 7.60864 17.7993Z" fill="#31AA52"/>
        <path d="M28 13.9999C28 13.1481 27.9229 12.2948 27.7708 11.4636L27.6476 10.791H14V17.3535H20.6419C19.9969 18.6365 19.0052 19.6833 17.8045 20.3962L22.5194 25.1112C23.0011 24.7403 23.4624 24.3364 23.8995 23.8994C26.5437 21.2551 28 17.7394 28 13.9999Z" fill="#3C79E6"/>
        <path d="M19.2591 8.74087L19.8391 9.32088L24.4795 4.68054L23.8995 4.10052C21.2553 1.45627 17.7396 0 14 0L10.7188 3.28125L14 6.5625C15.9866 6.5625 17.8543 7.33611 19.2591 8.74087Z" fill="#CF2D48"/>
        <path d="M14 6.5625V0C10.2604 0 6.74474 1.45627 4.10044 4.10047C3.66343 4.53748 3.25956 4.99877 2.88867 5.48051L7.60864 10.2005C8.90763 8.02375 11.2862 6.5625 14 6.5625Z" fill="#EB4132"/>
      </symbol>
      <symbol id="profile-icon" viewBox="0 0 20 20">
        <path d="M9.99935 9.99984C12.5777 9.99984 14.666 7.9115 14.666 5.33317C14.666 2.75484 12.5777 0.666504 9.99935 0.666504C7.42101 0.666504 5.33268 2.75484 5.33268 5.33317C5.33268 7.9115 7.42101 9.99984 9.99935 9.99984ZM9.99935 12.3332C6.88435 12.3332 0.666016 13.8965 0.666016 16.9998V19.3332H19.3327V16.9998C19.3327 13.8965 13.1143 12.3332 9.99935 12.3332Z" fill="#333333"/>
      </symbol>
      <symbol id="tick-icon" viewBox="0 0 14 10">
        <path d="M13.4175 0.199426C13.1516 -0.0664754 12.7206 -0.0664754 12.4546 0.199426L4.29768 8.35645L1.16229 5.22105C0.896414 4.95515 0.465355 4.95518 0.199426 5.22105C-0.0664754 5.48693 -0.0664754 5.91799 0.199426 6.18389L3.81625 9.80066C4.08205 10.0665 4.51343 10.0664 4.77912 9.80066L13.4175 1.16229C13.6834 0.896414 13.6834 0.465328 13.4175 0.199426Z" fill="#4F37C8"/>
      </symbol>
      <symbol id="link-arrow-icon" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8L7 2M7 2L1.42857 2.06461M7 2C7 2 6.92893 5.36396 6.8834 7.51943" stroke="#4F37C8" stroke-width="2" stroke-linecap="round"/>
      </symbol>
      <symbol id="double-quote-icon" viewBox="0 0 59 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8955 0C9.51045 7.01492 0 20.694 0 32.6194C0 41.5634 4.22687 47 11.2716 47C17.9642 47 22.5433 42.4403 22.5433 35.7761C22.5433 28.9366 18.8448 25.0784 12.5045 25.4291C14.794 14.7313 24.4806 5.26119 24.8328 4.38432C24.6567 2.63059 23.9522 1.05224 22.8955 0ZM57.0627 0C43.6776 7.01492 34.1672 20.694 34.1672 32.6194C34.1672 41.5634 38.394 47 45.4388 47C52.1313 47 56.7104 42.4403 56.7104 35.7761C56.7104 28.9366 53.0119 25.0784 46.6716 25.4291C48.9612 14.7313 58.6478 5.26119 59 4.38432C58.8239 2.63059 58.1194 1.05224 57.0627 0Z" fill="#DEDCFF"/>
      </symbol>
      <symbol id="warning-icon" viewBox="0 0 12 12">
        <path d="M6 0C2.68352 0 0 2.68376 0 6C0 9.31648 2.68376 12 6 12C9.31648 12 12 9.31624 12 6C12 2.68352 9.31624 0 6 0ZM6 11.0625C3.20173 11.0625 0.9375 8.79809 0.9375 6C0.9375 3.20173 3.20191 0.9375 6 0.9375C8.79827 0.9375 11.0625 3.20191 11.0625 6C11.0625 8.79827 8.79809 11.0625 6 11.0625Z" fill="#D83E3E"/>
        <path d="M6 3.02051C5.74111 3.02051 5.53125 3.23037 5.53125 3.48926V6.50784C5.53125 6.76673 5.74111 6.97659 6 6.97659C6.25889 6.97659 6.46875 6.76673 6.46875 6.50784V3.48926C6.46875 3.23037 6.25889 3.02051 6 3.02051Z" fill="#D83E3E"/>
        <path d="M6 8.81641C6.34949 8.81641 6.63281 8.53309 6.63281 8.18359C6.63281 7.8341 6.34949 7.55078 6 7.55078C5.65051 7.55078 5.36719 7.8341 5.36719 8.18359C5.36719 8.53309 5.65051 8.81641 6 8.81641Z" fill="#D83E3E"/>
      </symbol>
      <symbol id="paypal-icon" viewBox="0 0 246 60">
        <defs>
          <path id="path-1" d="M0.0363636364,60 L245.244848,60 L245.244848,0.497575758 L0.0363636364,0.497575758 L0.0363636364,60 L0.0363636364,60 Z"/>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="pp_fc_hl">
                <g id="pp_h_rgb">
                    <path d="M190.421818,26.4321212 C189.684848,31.2727273 185.987879,31.2727273 182.412121,31.2727273 L180.377576,31.2727273 L181.804848,22.2351515 C181.890909,21.6890909 182.361818,21.2866667 182.915152,21.2866667 L183.847879,21.2866667 C186.281212,21.2866667 188.580606,21.2866667 189.766061,22.6733333 C190.475152,23.5030303 190.689697,24.7327273 190.421818,26.4321212 L190.421818,26.4321212 Z M188.865455,13.8036364 L175.38,13.8036364 C174.458182,13.8036364 173.673333,14.4745455 173.529697,15.3854545 L168.07697,49.9630303 C167.969091,50.6448485 168.496364,51.2618182 169.187273,51.2618182 L176.106061,51.2618182 C176.751515,51.2618182 177.301212,50.7921212 177.401212,50.1545455 L178.947879,40.3521212 C179.091515,39.4412121 179.876364,38.770303 180.798182,38.770303 L185.065455,38.770303 C193.947879,38.770303 199.073939,34.4715152 200.413939,25.9521212 C201.01697,22.2260606 200.438182,19.2981818 198.693333,17.2484848 C196.775758,14.9951515 193.37697,13.8036364 188.865455,13.8036364 L188.865455,13.8036364 Z" id="Fill-1" fill="#2790C3"/>
                    <path d="M94.2418182,26.4321212 C93.5048485,31.2727273 89.8078788,31.2727273 86.2327273,31.2727273 L84.1981818,31.2727273 L85.6248485,22.2351515 C85.7115152,21.6890909 86.1824242,21.2866667 86.7351515,21.2866667 L87.6678788,21.2866667 C90.1018182,21.2866667 92.4012121,21.2866667 93.5860606,22.6733333 C94.2957576,23.5030303 94.510303,24.7327273 94.2418182,26.4321212 L94.2418182,26.4321212 Z M92.6860606,13.8036364 L79.2006061,13.8036364 C78.2787879,13.8036364 77.4939394,14.4745455 77.350303,15.3854545 L71.8969697,49.9630303 C71.789697,50.6448485 72.3169697,51.2618182 73.0072727,51.2618182 L79.4460606,51.2618182 C80.3678788,51.2618182 81.1533333,50.5909091 81.2969697,49.6806061 L82.7684848,40.3521212 C82.9115152,39.4412121 83.6969697,38.770303 84.6187879,38.770303 L88.8860606,38.770303 C97.7678788,38.770303 102.894545,34.4715152 104.234545,25.9521212 C104.837576,22.2260606 104.258788,19.2981818 102.513939,17.2484848 C100.595758,14.9951515 97.1969697,13.8036364 92.6860606,13.8036364 L92.6860606,13.8036364 Z" id="Fill-2" fill="#27346A"/>
                    <path d="M123.992121,38.8454545 C123.368485,42.5351515 120.439394,45.0121212 116.703636,45.0121212 C114.830909,45.0121212 113.331515,44.409697 112.367879,43.2690909 C111.412727,42.1381818 111.053333,40.5272727 111.355758,38.7333333 C111.937576,35.0769697 114.913333,32.52 118.592121,32.52 C120.426061,32.52 121.914545,33.1284848 122.897576,34.2793939 C123.886667,35.4387879 124.275152,37.0606061 123.992121,38.8454545 L123.992121,38.8454545 Z M132.990909,26.2775758 L126.533939,26.2775758 C125.980606,26.2775758 125.509697,26.6793939 125.423636,27.2266667 L125.139394,29.0321212 L124.687879,28.3775758 C123.289697,26.3478788 120.173333,25.669697 117.061212,25.669697 C109.927273,25.669697 103.833333,31.0757576 102.647273,38.6581818 C102.029697,42.4406061 102.906061,46.0551515 105.050909,48.5781818 C107.021212,50.8963636 109.833333,51.8612121 113.183636,51.8612121 C118.933939,51.8612121 122.12303,48.1672727 122.12303,48.1672727 L121.834545,49.9618182 C121.726667,50.6442424 122.253939,51.2618182 122.944848,51.2618182 L128.76,51.2618182 C129.681818,51.2618182 130.466667,50.5915152 130.610303,49.6806061 L134.101212,27.5763636 C134.209091,26.8939394 133.681818,26.2775758 132.990909,26.2775758 L132.990909,26.2775758 Z" id="Fill-3" fill="#27346A"/>
                    <path d="M220.171515,38.8454545 C219.547879,42.5351515 216.619394,45.0121212 212.883636,45.0121212 C211.010303,45.0121212 209.511515,44.409697 208.547273,43.2690909 C207.592121,42.1381818 207.232727,40.5272727 207.535758,38.7333333 C208.11697,35.0769697 211.092727,32.52 214.772121,32.52 C216.605455,32.52 218.094545,33.1284848 219.07697,34.2793939 C220.066061,35.4387879 220.454545,37.0606061 220.171515,38.8454545 L220.171515,38.8454545 Z M229.170303,26.2775758 L222.713333,26.2775758 C222.16,26.2775758 221.689091,26.6793939 221.60303,27.2266667 L221.318788,29.0321212 L220.867879,28.3775758 C219.469091,26.3478788 216.352727,25.669697 213.240606,25.669697 C206.107273,25.669697 200.012727,31.0757576 198.826667,38.6581818 C198.209697,42.4406061 199.086061,46.0551515 201.230303,48.5781818 C203.201212,50.8963636 206.012727,51.8612121 209.36303,51.8612121 C215.113333,51.8612121 218.302424,48.1672727 218.302424,48.1672727 L218.014545,49.9618182 C217.906061,50.6442424 218.433333,51.2618182 219.124848,51.2618182 L224.939394,51.2618182 C225.861212,51.2618182 226.646061,50.5915152 226.790303,49.6806061 L230.280606,27.5763636 C230.388485,26.8939394 229.861212,26.2775758 229.170303,26.2775758 L229.170303,26.2775758 Z" id="Fill-4" fill="#2790C3"/>
                    <g id="Group-10">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"/>
                        </mask>
                        <g id="Clip-6"/>
                        <path d="M167.381818,26.2775758 L160.891515,26.2775758 C160.270909,26.2775758 159.690303,26.5854545 159.341818,27.0987879 L150.389091,40.2848485 L146.594545,27.6139394 C146.357576,26.8212121 145.627879,26.2775758 144.8,26.2775758 L138.42,26.2775758 C137.649697,26.2775758 137.107273,27.0351515 137.355758,27.7642424 L144.502424,48.7412121 L137.780606,58.2260606 C137.252727,58.970303 137.785455,60 138.697576,60 L145.181818,60 C145.796364,60 146.371515,59.6987879 146.721818,59.1933333 L168.306061,28.0424242 C168.822424,27.2969697 168.289091,26.2775758 167.381818,26.2775758" id="Fill-5" fill="#27346A" mask="url(#mask-2)"/>
                        <path d="M236.781212,14.7533333 L231.246667,49.9630303 C231.139394,50.6454545 231.666667,51.2618182 232.35697,51.2618182 L237.923636,51.2618182 C238.845455,51.2618182 239.630303,50.5909091 239.773939,49.6806061 L245.230909,15.1030303 C245.338788,14.4206061 244.811515,13.8036364 244.120606,13.8036364 L237.891515,13.8036364 C237.338182,13.8036364 236.867273,14.2066667 236.781212,14.7533333" id="Fill-7" fill="#2790C3" mask="url(#mask-2)"/>
                        <path d="M25.5466667,57.5484848 L27.3593939,46.0539394 L27.4763636,45.4169697 C27.5636364,44.8630303 27.8472727,44.3563636 28.2745455,43.9915152 C28.7018182,43.6266667 29.2466667,43.4254545 29.8084848,43.4254545 L31.2478788,43.4254545 C33.6472727,43.4254545 35.8515152,43.1690909 37.8,42.6636364 C39.8787879,42.1248485 41.7278788,41.2854545 43.2951515,40.1709091 C44.9569697,38.9890909 46.3527273,37.4612121 47.4442424,35.6309091 C48.5957576,33.7 49.4412121,31.3751515 49.9575758,28.7218182 C50.4139394,26.3787879 50.4993939,24.2812121 50.2127273,22.4866667 C49.9090909,20.5909091 49.1787879,18.9733333 48.0424242,17.6775758 C47.3533333,16.8927273 46.4715152,16.2127273 45.4218182,15.6563636 L45.3969697,15.6430303 L45.3963636,15.6127273 C45.7630303,13.2739394 45.7490909,11.3230303 45.3545455,9.64363636 C44.9581818,7.96060606 44.1557576,6.44606061 42.8993939,5.01454545 C40.2963636,2.04848485 35.5618182,0.544242424 28.8278788,0.544242424 L10.3339394,0.544242424 C9.71575758,0.544242424 9.11575758,0.765454545 8.64545455,1.16727273 C8.17515152,1.56909091 7.86363636,2.12727273 7.76666667,2.73818182 L0.0660606061,51.570303 C-0.00424242424,52.0157576 0.123636364,52.4684848 0.416969697,52.810303 C0.71030303,53.1539394 1.1369697,53.3509091 1.58787879,53.3509091 L13.0612121,53.3509091 L13.0509091,53.4054545 L12.2630303,58.4018182 C12.2024242,58.790303 12.3139394,59.1842424 12.5690909,59.4824242 C12.8242424,59.7812121 13.1957576,59.9533333 13.5884848,59.9533333 L23.2115152,59.9533333 C23.7515152,59.9533333 24.2745455,59.76 24.6848485,59.4090909 C25.0951515,59.0581818 25.3678788,58.5715152 25.4521212,58.0387879 L25.5466667,57.5484848" id="Fill-8" fill="#27346A" mask="url(#mask-2)"/>
                        <path d="M18.9539394,15.6763636 C19.0757576,14.9024242 19.5721212,14.2690909 20.2406061,13.9484848 C20.5448485,13.8030303 20.8842424,13.7218182 21.24,13.7218182 L35.7363636,13.7218182 C37.4539394,13.7218182 39.0551515,13.8345455 40.5193939,14.070303 C40.9375758,14.1375758 41.3442424,14.2151515 41.74,14.3030303 C42.1357576,14.3909091 42.5193939,14.4884848 42.8909091,14.5975758 C43.0769697,14.6515152 43.2593939,14.7084848 43.4393939,14.7684848 C44.1581818,15.0072727 44.8272727,15.2884848 45.4430303,15.6145455 C46.1690909,10.9860606 45.4381818,7.83636364 42.9345455,4.98363636 C40.1781818,1.8430303 35.1987879,0.497575758 28.8278788,0.497575758 L10.3339394,0.497575758 C9.03212121,0.497575758 7.92363636,1.44484848 7.72060606,2.73090909 L0.02,51.5630303 C-0.132121212,52.5266667 0.612727273,53.3975758 1.58787879,53.3975758 L13.0048485,53.3975758 L18.9539394,15.6763636" id="Fill-9" fill="#27346A" mask="url(#mask-2)"/>
                    </g>
                    <path d="M45.4430303,15.6145455 L45.4430303,15.6145455 C45.3878788,15.9678788 45.3266667,16.3272727 45.2539394,16.6975758 C42.8157576,29.2193939 34.4715152,33.5484848 23.8145455,33.5484848 L18.3878788,33.5484848 C17.0860606,33.5484848 15.9866667,34.4963636 15.7836364,35.7824242 L12.2169697,58.3951515 C12.0842424,59.2375758 12.7357576,60 13.5884848,60 L23.2121212,60 C24.3509091,60 25.3206061,59.1715152 25.4981818,58.0460606 L25.5927273,57.5569697 L27.4054545,46.0612121 L27.5224242,45.4260606 C27.7,44.3006061 28.669697,43.4721212 29.8084848,43.4721212 L31.2478788,43.4721212 C40.5709091,43.4721212 47.8709091,39.6842424 50.0042424,28.730303 C50.8951515,24.1533333 50.4345455,20.3327273 48.0775758,17.6466667 C47.3648485,16.8345455 46.4775758,16.1624242 45.4430303,15.6145455" id="Fill-11" fill="#2790C3"/>
                    <path d="M42.8909091,14.5975758 C42.5193939,14.4884848 42.1357576,14.3909091 41.74,14.3030303 C41.3442424,14.2151515 40.9375758,14.1375758 40.5193939,14.070303 C39.0551515,13.8345455 37.4539394,13.7218182 35.7363636,13.7218182 L21.24,13.7218182 C20.8842424,13.7218182 20.5448485,13.8030303 20.2406061,13.9484848 C19.5721212,14.2690909 19.0757576,14.9024242 18.9539394,15.6763636 L15.8727273,35.2121212 L15.7836364,35.7824242 C15.9866667,34.4963636 17.0860606,33.5484848 18.3878788,33.5484848 L23.8145455,33.5484848 C34.4715152,33.5484848 42.8157576,29.2193939 45.2539394,16.6975758 C45.3266667,16.3272727 45.3878788,15.9678788 45.4430303,15.6145455 C44.8272727,15.2884848 44.1581818,15.0072727 43.4393939,14.7684848 C43.2593939,14.7084848 43.0769697,14.6515152 42.8909091,14.5975758" id="Fill-12" fill="#1F264F"/>
                </g>
            </g>
        </g>
      </symbol>
      <symbol id="razorpay_logo" viewBox="0 0 50 11">
        <g fill="none" fill-rule="evenodd">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.063 2.53375C13.3665 2.86462 13.4353 3.3396 13.2693 3.95825C13.1528 4.39351 12.9377 4.76718 12.6243 5.07956C12.3108 5.39209 11.9214 5.61598 11.4565 5.75063C11.821 5.83025 12.0286 6.09677 12.0789 6.55023L12.3247 8.5813H10.8081L10.5557 6.40306C10.5339 6.23172 10.4636 6.1122 10.3435 6.04466C10.2239 5.97726 10.0598 5.94351 9.85181 5.94351H8.92358L8.21655 8.5813H6.80103L8.55444 2.03752H11.468C12.2275 2.03752 12.7595 2.20287 13.063 2.53375ZM11.4592 4.64308C11.686 4.50522 11.8389 4.28929 11.9177 3.99509C11.9966 3.70105 11.9595 3.48509 11.8066 3.34709C11.6533 3.20926 11.3716 3.14032 10.9612 3.14032H9.65601L9.19775 4.84999H10.5029C10.9138 4.84999 11.2322 4.78093 11.4592 4.64308Z" fill="#072654"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1528 4.58334L17.3301 3.92159H18.6995L17.4507 8.58131H16.0815L16.2664 7.89204C16.0613 8.13094 15.812 8.31799 15.5188 8.45262C15.2253 8.58743 14.916 8.6548 14.5913 8.6548C14.187 8.6548 13.8562 8.55699 13.5994 8.36076C13.3425 8.16469 13.1755 7.88441 13.0986 7.51976C13.0215 7.15527 13.0471 6.73409 13.1753 6.25601C13.3032 5.77814 13.5046 5.35236 13.7795 4.97855C14.0542 4.60489 14.3755 4.31541 14.7429 4.11003C15.1104 3.90479 15.4934 3.80206 15.8916 3.80206C16.2163 3.80206 16.4885 3.87268 16.7083 4.01358C16.9277 4.1545 17.0759 4.34431 17.1528 4.58334ZM16.1631 7.24863C16.4092 7.01587 16.5913 6.67874 16.7097 6.23769C16.8279 5.79646 16.8264 5.45796 16.7061 5.22215C16.5854 4.98634 16.3533 4.86819 16.0103 4.86819C15.667 4.86819 15.3679 4.99091 15.1123 5.23573C14.8564 5.48103 14.6707 5.82108 14.5542 6.25601C14.4375 6.69127 14.4417 7.02353 14.5674 7.25322C14.6924 7.48306 14.9294 7.59787 15.2793 7.59787C15.6221 7.59787 15.9167 7.48154 16.1631 7.24863Z" fill="#072654"/>
            <path d="M23.6232 3.92157H19.6435L19.3653 4.9602H21.6813L18.6192 7.60701L18.3579 8.5813H22.4662L22.7447 7.54282H20.2634L23.3719 4.85902L23.6232 3.92157Z" fill="#072654"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.72 3.80206C27.2102 3.80206 27.6128 3.90016 27.928 4.0961C28.2427 4.29233 28.4504 4.57416 28.5515 4.9417C28.6521 5.30939 28.6367 5.73825 28.5054 6.22852C28.3738 6.71878 28.1592 7.14748 27.8618 7.51517C27.5642 7.88286 27.2051 8.16469 26.7854 8.36076C26.365 8.55699 25.9099 8.6548 25.4197 8.6548C24.9233 8.6548 24.5161 8.55699 24.198 8.36076C23.8794 8.16469 23.6704 7.88286 23.5698 7.51517C23.469 7.14748 23.4844 6.71878 23.6157 6.22852C23.7471 5.73825 23.9617 5.30939 24.2595 4.9417C24.5569 4.57416 24.9172 4.29233 25.3406 4.0961C25.7639 3.90016 26.2236 3.80206 26.72 3.80206ZM26.5796 7.25797C26.821 7.03131 27.0034 6.6879 27.1267 6.22852C27.2483 5.77505 27.2493 5.4335 27.1301 5.2038C27.0107 4.97395 26.7795 4.85902 26.4365 4.85902C26.0872 4.85902 25.79 4.97395 25.5447 5.2038C25.2996 5.4335 25.1162 5.77505 24.9944 6.22852C24.75 7.14165 24.9858 7.59784 25.7029 7.59784C26.0459 7.59784 26.3381 7.4846 26.5796 7.25797Z" fill="#072654"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.6936 4.58334L42.8708 3.92159H44.2402L42.9917 8.58131H41.6223L41.8071 7.89204C41.6021 8.13094 41.353 8.31799 41.0596 8.45262C40.7661 8.58743 40.4568 8.6548 40.1323 8.6548C39.7278 8.6548 39.3972 8.55699 39.1404 8.36076C38.8831 8.16469 38.7163 7.88441 38.6394 7.51976C38.5627 7.15527 38.5881 6.73409 38.7161 6.25601C38.8442 5.77814 39.0457 5.35236 39.3203 4.97855C39.5952 4.60489 39.9165 4.31541 40.2839 4.11003C40.6511 3.90479 41.0342 3.80206 41.4324 3.80206C41.7571 3.80206 42.0295 3.87268 42.2493 4.01358C42.4688 4.1545 42.6169 4.34431 42.6936 4.58334ZM41.7039 7.24863C41.95 7.01587 42.1321 6.67874 42.2505 6.23769C42.3687 5.79646 42.3672 5.45796 42.2468 5.22215C42.1262 4.98634 41.894 4.86819 41.551 4.86819C41.2078 4.86819 40.9087 4.99091 40.6531 5.23573C40.3972 5.48103 40.2114 5.82108 40.095 6.25601C39.9783 6.69127 39.9824 7.02353 40.1082 7.25322C40.2332 7.48306 40.4707 7.59787 40.8196 7.59787C41.1626 7.59787 41.4578 7.48154 41.7039 7.24863Z" fill="#072654"/>
            <path d="M32.8103 5.17156L33.1593 3.90323C33.0407 3.84194 32.8836 3.81125 32.6877 3.81125C32.375 3.81125 32.0739 3.88933 31.7839 4.04566C31.5348 4.17979 31.3227 4.36805 31.1435 4.60394L31.3251 3.92093L30.9286 3.92159H29.9562L28.698 8.58129H30.0863L30.7388 6.14585C30.8339 5.79052 31.0045 5.51328 31.2511 5.31415C31.4972 5.11502 31.8044 5.01536 32.1721 5.01536C32.3988 5.01536 32.6113 5.06747 32.8103 5.17156Z" fill="#072654"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36.6611 3.80215C37.0591 3.80215 37.387 3.90469 37.6448 4.10994C37.9021 4.31534 38.0684 4.6048 38.1426 4.97847C38.2168 5.35228 38.1904 5.77805 38.0623 6.25595C37.9343 6.73398 37.7356 7.15535 37.4663 7.51968C37.1973 7.88432 36.8799 8.16476 36.5149 8.3607C36.1497 8.55693 35.7654 8.65474 35.3608 8.65474C35.0359 8.65474 34.7629 8.58734 34.5417 8.45255C34.3203 8.3179 34.1743 8.13084 34.104 7.89214L33.3872 10.5665H31.9993L33.4226 5.25417L33.4341 5.21007L33.7791 3.92151L34.866 3.92085H35.1343L34.9011 4.66302L34.8965 4.69359C35.0928 4.41774 35.3486 4.20058 35.6643 4.04103C35.9792 3.88177 36.3118 3.80215 36.6611 3.80215ZM36.1318 7.25314C36.377 7.02345 36.5579 6.69121 36.6746 6.25595C36.791 5.82114 36.7886 5.48094 36.6677 5.23582C36.5464 4.99083 36.3174 4.8681 35.9805 4.8681C35.6372 4.8681 35.3403 4.98625 35.0903 5.22206C34.8403 5.45787 34.6565 5.7964 34.5381 6.23759C34.4199 6.6788 34.4229 7.01581 34.5476 7.24857C34.6721 7.48148 34.9058 7.59778 35.249 7.59778C35.592 7.59778 35.886 7.483 36.1318 7.25314Z" fill="#072654"/>
            <path d="M49.9879 3.92288L49.9888 3.92159H49.148C49.1212 3.92159 49.0975 3.92235 49.0729 3.92288H48.6365L48.4134 4.23374C48.3955 4.25716 48.3777 4.28074 48.3584 4.30808L48.3341 4.34408L46.5608 6.8137L46.1931 3.92159H44.7407L45.4762 8.31631L43.8518 10.5665H43.8981H44.7275H45.2989L45.6926 10.0089C45.7042 9.99229 45.7141 9.97868 45.7265 9.96112L46.1859 9.30982L46.1991 9.29118L48.2546 6.37731L49.9865 3.9248L49.9888 3.92288H49.9879Z" fill="#072654"/>
            <path d="M3.23411 2.78718L2.8186 4.31627L5.19614 2.77859L3.64131 8.57985L5.22024 8.5813L7.51713 0.0127106L3.23411 2.78718Z" fill="#3395FF"/>
            <path d="M0.674906 6.14253L0.0212402 8.58129H3.25769C3.25769 8.58129 4.58147 3.6213 4.58184 3.61987C4.5806 3.62066 0.674906 6.14253 0.674906 6.14253Z" fill="#072654"/>
        </g>
      </symbol>
      <symbol id="invoice-icon" viewBox="0 0 14 18">
        <path d="M11.4084 0H2.31565C1.21906 0 0.330078 0.898206 0.330078 2.00625V18L2.79866 15.6393L4.90246 18L6.86204 15.6393L8.82162 18L10.9254 15.6393L13.394 18V2.00625C13.394 0.898206 12.505 0 11.4084 0ZM2.86307 3.62344H9.49477C9.82299 3.62344 10.0886 3.89209 10.0886 4.22344C10.0886 4.55479 9.82299 4.82344 9.49477 4.82344H2.86307C2.53485 4.82344 2.26925 4.55479 2.26925 4.22344C2.26925 3.89209 2.53485 3.62344 2.86307 3.62344ZM2.86307 5.73281H6.17892C6.50714 5.73281 6.77273 6.00147 6.77273 6.33281C6.77273 6.66416 6.50714 6.93281 6.17892 6.93281H2.86307C2.53485 6.93281 2.26925 6.66416 2.26925 6.33281C2.26925 6.00147 2.53485 5.73281 2.86307 5.73281ZM10.861 13.6406H2.86307C2.53485 13.6406 2.26925 13.372 2.26925 13.0406C2.26925 12.7093 2.53485 12.4406 2.86307 12.4406H10.861C11.1892 12.4406 11.4548 12.7093 11.4548 13.0406C11.4548 13.372 11.1892 13.6406 10.861 13.6406ZM10.861 11.7563H2.86307C2.53485 11.7563 2.26925 11.4876 2.26925 11.1562C2.26925 10.8249 2.53485 10.5563 2.86307 10.5563H10.861C11.1892 10.5563 11.4548 10.8249 11.4548 11.1562C11.4548 11.4876 11.1892 11.7563 10.861 11.7563ZM10.861 9.6H2.86307C2.53485 9.6 2.26925 9.33135 2.26925 9C2.26925 8.66865 2.53485 8.4 2.86307 8.4H10.861C11.1892 8.4 11.4548 8.66865 11.4548 9C11.4548 9.33135 11.1892 9.6 10.861 9.6Z" fill="#110A61"/>
      </symbol>
      <symbol id="rz-logo"  viewBox="0 0 46 50">
        <path d="M31.8276 22.5919L39.0803 12.8863L44.5617 5.60716L22.9889 5.58289C22.2621 3.92232 21.0669 2.50973 19.5495 1.51812C18.0321 0.526519 16.2585 -0.00105872 14.4458 1.59512e-06C14.2473 1.59512e-06 14.0488 1.57607e-06 13.8547 0.0198538H7.96076V0.0044132H0.0926514V30.4445H7.96076V19.8258L18.8795 39.8986L24.0675 32.9724L25.9535 30.4467H38.6766L44.5815 22.6095L31.8276 22.5919ZM13.1929 12.7076H7.93208V5.97112H10.7776H12.9084C12.979 5.97112 13.0496 5.97112 13.129 5.97112C14.0091 5.98286 14.8497 6.33842 15.4711 6.96181C16.0925 7.5852 16.4454 8.42694 16.4543 9.30709C16.4632 10.1872 16.1275 11.036 15.5189 11.6718C14.9103 12.3077 14.077 12.6803 13.1973 12.7099L13.1929 12.7076ZM19.9405 25.3822L16.1818 18.4736C17.5968 18.2056 18.9305 17.6137 20.0785 16.7443C21.2266 15.8748 22.1578 14.7514 22.7992 13.462H28.8475L19.9405 25.3822Z" fill="#0D2213"/>
        <path d="M34.2738 5.57628L44.7999 5.56305L26.1851 30.3739L19.475 39.1001L19.9361 25.2918L28.8409 13.4598L22.9337 13.462L22.9889 5.59834L34.2738 5.57628Z" fill="#AA0B17"/>
        <path d="M31.5827 22.5919H45.1131L38.9369 30.4423L25.7329 30.4467L31.5827 22.5919Z" fill="#AA0B17"/>
        <path d="M3.05504 46.5226H1.1029V49.8843H0V41.575H2.75284C3.68958 41.575 4.4094 41.7882 4.91233 42.2147C5.41525 42.6412 5.66451 43.261 5.6601 44.0742C5.67031 44.5584 5.52326 45.0328 5.24099 45.4263C4.9457 45.8227 4.53969 46.1228 4.07412 46.2888L6.02626 49.8181V49.8865H4.85939L3.05504 46.5226ZM1.1029 45.6271H2.79255C3.26317 45.652 3.72624 45.501 4.09177 45.2036C4.25163 45.0609 4.37743 44.8842 4.45989 44.6864C4.54235 44.4887 4.57937 44.2749 4.56822 44.061C4.58183 43.8443 4.54847 43.6273 4.47043 43.4248C4.39238 43.2222 4.27149 43.0389 4.11603 42.8875C3.74111 42.5906 3.26996 42.4421 2.79255 42.4706H1.1029V45.6271Z" fill="#0D2213"/>
        <path d="M13.601 45.9976C13.6178 46.7298 13.4779 47.457 13.1907 48.1307C12.949 48.6992 12.5436 49.1829 12.026 49.5203C11.4968 49.8479 10.8836 50.0143 10.2614 49.999C9.64514 50.0147 9.03816 49.8464 8.51798 49.5156C7.9978 49.1848 7.588 48.7065 7.34091 48.1417C7.04587 47.4921 6.89535 46.7861 6.89975 46.0726V45.4727C6.88523 44.7451 7.02742 44.0229 7.31665 43.3551C7.55661 42.7819 7.96393 42.2944 8.48536 41.9563C9.0068 41.6183 9.61809 41.4454 10.2393 41.4603C10.8619 41.4475 11.4748 41.6153 12.004 41.9434C12.5263 42.2815 12.9351 42.7687 13.1775 43.3419C13.4681 44.0141 13.6124 44.7404 13.601 45.4727V45.9976ZM12.4981 45.4616C12.4981 44.4764 12.3003 43.7198 11.9047 43.1919C11.7137 42.9343 11.4623 42.7276 11.1727 42.59C10.8831 42.4523 10.5641 42.3878 10.2438 42.4022C9.92716 42.3899 9.61238 42.4555 9.32703 42.5931C9.04167 42.7308 8.79445 42.9364 8.60705 43.1919C8.21 43.7183 8.0056 44.4536 7.99383 45.3977V46.0087C7.99383 46.9645 8.19456 47.7152 8.59602 48.2608C8.78288 48.5241 9.03234 48.7368 9.32193 48.8797C9.61151 49.0226 9.9321 49.0911 10.2548 49.0792C10.5711 49.0943 10.8864 49.0316 11.1729 48.8967C11.4594 48.7617 11.7084 48.5585 11.8981 48.3049C12.2981 47.7829 12.4981 47.0403 12.4981 46.077V45.4616Z" fill="#0D2213"/>
        <path d="M19.7133 47.7116H16.2303L15.4472 49.8865H14.3179L17.492 41.575H18.4538L21.6323 49.8865H20.5052L19.7133 47.7116ZM16.5612 46.816H19.3868L17.9707 42.9294L16.5612 46.816Z" fill="#0D2213"/>
        <path d="M29.1365 49.8865H28.0336L23.8426 43.4897V49.8865H22.7397V41.575H23.8426L28.0336 48.0093V41.575H29.1233L29.1365 49.8865Z" fill="#0D2213"/>
        <path d="M36.934 41.575V47.2263C36.9457 47.58 36.8863 47.9325 36.7594 48.2629C36.6324 48.5932 36.4405 48.8948 36.195 49.1497C35.661 49.667 34.9529 49.966 34.2098 49.988L33.9186 49.999C33.0099 49.999 32.2849 49.7534 31.7437 49.2622C31.2026 48.7711 30.9283 48.0954 30.921 47.2351V41.575H32.0062V47.2042C31.9797 47.4614 32.011 47.7213 32.0979 47.9648C32.1848 48.2083 32.3251 48.4293 32.5084 48.6116C32.6918 48.7939 32.9136 48.9328 33.1576 49.0183C33.4016 49.1038 33.6616 49.1336 33.9186 49.1056C34.5392 49.1056 35.0142 48.9402 35.3436 48.6093C35.5157 48.4214 35.6481 48.2007 35.733 47.9604C35.8179 47.7201 35.8535 47.4652 35.8377 47.2108V41.575H36.934Z" fill="#0D2213"/>
        <path d="M39.4862 48.9887H44.2419V49.8843H38.1913V49.0615L42.7463 42.4684H38.2641V41.5728H44.0588V42.3735L39.4862 48.9887Z" fill="#0D2213"/>
      </symbol>
      <symbol id="success-illustration"  viewBox="0 0 310 189">
        <circle cx="153" cy="107" r="78" fill="#1F27A3" fill-opacity="0.2"/>
        <circle cx="153" cy="107" r="63" fill="#1F27A3" fill-opacity="0.5"/>
        <circle cx="153" cy="107" r="50" fill="#1F27A3"/>
        <path d="M145.91 123.184C146.77 124.043 148.23 124.043 149.09 123.184L174.355 97.918C175.215 97.0586 175.215 95.5977 174.355 94.7383L171.262 91.6445C170.402 90.7852 169.027 90.7852 168.168 91.6445L147.543 112.27L137.832 102.645C136.973 101.785 135.598 101.785 134.738 102.645L131.645 105.738C130.785 106.598 130.785 108.059 131.645 108.918L145.91 123.184Z" fill="white"/>
        <path d="M35.9202 76.2803C38.628 75.7576 43.2702 70.8616 40.1763 55.4592C42.0326 61.522 47.4508 73.1906 54.2728 71.3627C51.3225 72.1532 46.4064 77.5655 50.2083 92.8831C50.2296 92.9665 50.2502 93.0489 50.2702 93.1303C50.2494 93.0476 50.2287 92.9652 50.2083 92.8831C48.6697 86.8483 43.7738 75.4567 35.9202 76.2803Z" fill="#1F27A3" fill-opacity="0.1"/>
        <path d="M0 118.211C2.75084 118.327 8.50202 116.246 9.5 107C9.72391 110.737 11.9374 118.211 19 118.211C15.9457 118.211 9.79633 120.544 9.50419 129.85C9.50315 129.901 9.50175 129.951 9.5 130C9.50123 129.95 9.50263 129.9 9.50419 129.85C9.57986 126.177 7.79923 118.941 0 118.211Z" fill="#1F27A3" fill-opacity="0.8"/>
        <path d="M169.905 10.1943C172.358 11.4441 178.454 11.9455 183.209 3.95313C181.857 7.44441 180.76 15.1617 187.182 18.1006C184.405 16.8296 177.842 16.3919 173.704 24.7324C173.682 24.7781 173.66 24.8231 173.638 24.8673C173.66 24.822 173.682 24.7771 173.704 24.7324C175.301 21.4244 176.693 14.1032 169.905 10.1943Z" fill="#1F27A3" fill-opacity="0.8"/>
        <path d="M59.6001 158.676C62.1843 159.626 68.2961 159.401 72.0697 150.901C71.1418 154.528 70.9674 162.321 77.6927 164.477C74.7843 163.545 68.2163 163.888 65.0966 172.661C65.0801 172.709 65.0634 172.756 65.0467 172.802C65.0632 172.755 65.0798 172.708 65.0966 172.661C66.29 169.186 66.8041 161.752 59.6001 158.676Z" fill="#1F27A3" fill-opacity="0.1"/>
        <path d="M207.97 176.284C210.723 176.361 216.444 174.2 217.312 164.94C217.588 168.674 219.907 176.116 226.969 176.017C223.914 176.06 217.799 178.479 217.638 187.788C217.637 187.839 217.637 187.889 217.635 187.938C217.636 187.888 217.637 187.838 217.638 187.788C217.662 184.115 215.779 176.904 207.97 176.284Z" fill="#1F27A3" fill-opacity="0.8"/>
        <path d="M228 45.2111C230.751 45.3266 236.502 43.2462 237.5 34C237.724 37.737 239.937 45.2111 247 45.2111C243.946 45.2111 237.796 47.5438 237.504 56.8497C237.503 56.9005 237.502 56.9506 237.5 57C237.501 56.9497 237.503 56.8996 237.504 56.8497C237.58 53.1772 235.799 45.9407 228 45.2111Z" fill="#1F27A3" fill-opacity="0.1"/>
        <path d="M248.388 119.027C249.94 118.668 252.899 116.845 252.313 112.424C252.9 114.117 255.063 117.238 259.013 116.18C257.305 116.637 254.155 118.636 255.143 122.979C255.149 123.003 255.154 123.026 255.16 123.049C255.154 123.026 255.149 123.003 255.143 122.979C254.731 121.271 252.84 118.195 248.388 119.027Z" fill="#1F27A3"/>
        <path d="M71.0418 44.8661C72.3933 45.7103 75.7744 46.5134 78.4858 42.9727C77.7044 44.5853 77.0269 48.3217 80.568 50.3661C79.0366 49.482 75.3956 48.6681 73.0238 52.4379C73.0112 52.4586 72.9985 52.479 72.9858 52.4989C72.9984 52.4785 73.0111 52.4581 73.0238 52.4379C73.94 50.9387 74.7777 47.426 71.0418 44.8661Z" fill="#1F27A3"/>
        <path d="M283.995 71.4632C286.279 73.0083 293.024 72.8289 301.727 59.75C298.753 65.3497 294.333 77.4319 300.449 80.9632C297.804 79.436 290.501 79.7869 282.358 93.3064C282.314 93.3804 282.271 93.4533 282.227 93.525C282.271 93.4517 282.315 93.3789 282.358 93.3064C285.537 87.9512 290.131 76.4342 283.995 71.4632Z" fill="#1F27A3" fill-opacity="0.8"/>
      </symbol>
      <symbol id="form-loader" viewBox="0 0 30 31">
       <path d="M17.8125 3.5625C17.8125 2.03906 16.5234 0.75 15 0.75C13.418 0.75 12.1875 2.03906 12.1875 3.5625C12.1875 5.14453 13.418 6.375 15 6.375C16.5234 6.375 17.8125 5.14453 17.8125 3.5625ZM15 25.125C13.418 25.125 12.1875 26.4141 12.1875 27.9375C12.1875 29.5195 13.418 30.75 15 30.75C16.5234 30.75 17.8125 29.5195 17.8125 27.9375C17.8125 26.4141 16.5234 25.125 15 25.125ZM27.1875 12.9375C25.6055 12.9375 24.375 14.2266 24.375 15.75C24.375 17.332 25.6055 18.5625 27.1875 18.5625C28.7109 18.5625 30 17.332 30 15.75C30 14.2266 28.7109 12.9375 27.1875 12.9375ZM5.625 15.75C5.625 14.2266 4.33594 12.9375 2.8125 12.9375C1.23047 12.9375 0 14.2266 0 15.75C0 17.332 1.23047 18.5625 2.8125 18.5625C4.33594 18.5625 5.625 17.332 5.625 15.75ZM6.32812 21.6094C4.80469 21.6094 3.51562 22.8398 3.51562 24.4219C3.51562 25.9453 4.80469 27.2344 6.32812 27.2344C7.91016 27.2344 9.14062 25.9453 9.14062 24.4219C9.14062 22.8398 7.91016 21.6094 6.32812 21.6094ZM23.6133 21.6094C22.0312 21.6094 20.8008 22.8398 20.8008 24.4219C20.8008 25.9453 22.0312 27.2344 23.6133 27.2344C25.1367 27.2344 26.4258 25.9453 26.4258 24.4219C26.4258 22.8398 25.1367 21.6094 23.6133 21.6094ZM6.32812 4.32422C4.80469 4.32422 3.51562 5.61328 3.51562 7.13672C3.51562 8.71875 4.80469 9.94922 6.32812 9.94922C7.91016 9.94922 9.14062 8.71875 9.14062 7.13672C9.14062 5.61328 7.91016 4.32422 6.32812 4.32422Z" fill="white"/>
      </symbol>
      <symbol id="check-icon" viewBox="0 0 16 16">
       <circle cx="8" cy="8" r="8" fill="white"/>
        <path d="M6.70312 10.875C6.85938 11.0312 7.125 11.0312 7.28125 10.875L11.875 6.28125C12.0312 6.125 12.0312 5.85938 11.875 5.70312L11.3125 5.14062C11.1562 4.98438 10.9062 4.98438 10.75 5.14062L7 8.89062L5.23438 7.14062C5.07812 6.98438 4.82812 6.98438 4.67188 7.14062L4.10938 7.70312C3.95312 7.85938 3.95312 8.125 4.10938 8.28125L6.70312 10.875Z" fill="#4F37C8"/>
      </symbol>
      <symbol id="info-icon" viewBox="0 0 752 752">
        <path d="m376 208.36c-44.461 0-87.102 17.66-118.54 49.102-31.441 31.441-49.102 74.082-49.102 118.54 0 44.465 17.66 87.105 49.102 118.55 31.441 31.438 74.082 49.102 118.54 49.102 44.465 0 87.105-17.664 118.55-49.102 31.438-31.441 49.102-74.082 49.102-118.55 0-29.426-7.7461-58.336-22.461-83.824-14.715-25.484-35.875-46.648-61.363-61.363-25.484-14.711-54.395-22.457-83.824-22.457zm14.207 256.68h-28.414v-107.03h28.414zm0-142.55h-28.414v-35.992h28.414z"/>
      </symbol>
      <symbol viewBox="0 0 10 10" id="close-btn">
        <path d="M9.62002 1.63001C9.94495 1.30509 9.94326 0.776593 9.61996 0.453289L9.54675 0.380084C9.22179 0.0551208 8.69557 0.054469 8.37002 0.380015L5.14797 3.60207C5.06626 3.68378 4.93462 3.68462 4.85207 3.60207L1.63001 0.380015C1.30509 0.0550898 0.776593 0.0567791 0.453289 0.380084L0.380084 0.453289C0.0551208 0.778252 0.054469 1.30447 0.380015 1.63001L3.60207 4.85207C3.68378 4.93378 3.68462 5.06542 3.60207 5.14797L0.380015 8.37002C0.0550898 8.69495 0.0567791 9.22345 0.380084 9.54675L0.453289 9.61996C0.778252 9.94492 1.30447 9.94557 1.63001 9.62002L4.85207 6.39797C4.93378 6.31626 5.06542 6.31542 5.14797 6.39797L8.37002 9.62002C8.69495 9.94495 9.22345 9.94326 9.54675 9.61996L9.61996 9.54675C9.94492 9.22179 9.94557 8.69557 9.62002 8.37002L6.39797 5.14797C6.31626 5.06626 6.31542 4.93462 6.39797 4.85207L9.62002 1.63001Z" fill="#9B9B9B"/>
      </symbol>
      <symbol id="arrow-icon-blue" viewBox="0 0 8 14" >
        <path d="M0.999999 13L7 7L1 1" stroke-width="2" stroke-linejoin="round"/>
      </symbol>
      <symbol viewBox="0 0 9 9" id="external-icon">
        <path d="M0.292893 6.79289C-0.0976311 7.18342 -0.0976311 7.81658 0.292893 8.20711C0.683417 8.59763 1.31658 8.59763 1.70711 8.20711L0.292893 6.79289ZM7 1.5L7.99978 1.52112L8.0216 0.488086L6.9884 0.500067L7 1.5ZM1.41698 0.564678C0.864728 0.571082 0.422234 1.02396 0.428639 1.57621C0.435043 2.12845 0.88792 2.57095 1.44017 2.56454L1.41698 0.564678ZM5.88362 6.99831C5.87195 7.55047 6.31011 8.00755 6.86227 8.01921C7.41444 8.03088 7.87151 7.59272 7.88317 7.04056L5.88362 6.99831ZM1.70711 8.20711L7.70711 2.20711L6.29289 0.792893L0.292893 6.79289L1.70711 8.20711ZM1.44017 2.56454L7.0116 2.49993L6.9884 0.500067L1.41698 0.564678L1.44017 2.56454ZM6.00022 1.47888L5.88362 6.99831L7.88317 7.04056L7.99978 1.52112L6.00022 1.47888Z" fill="#0055BF"/>
      </symbol>
      <symbol viewBox="0 0 14 14" id="help-icon-new">
        <path d="M6.99991 5C6.86741 5 6.74019 5.05266 6.64636 5.14636C6.55266 5.24019 6.5 5.36741 6.5 5.49991V10.2501C6.5 10.4286 6.59528 10.5937 6.74995 10.683C6.90462 10.7722 7.0952 10.7722 7.25 10.683C7.40467 10.5937 7.49995 10.4286 7.49995 10.2501V5.49991C7.49995 5.36741 7.4473 5.24019 7.35347 5.14636C7.25977 5.05266 7.13255 5 6.99991 5H6.99991Z" fill="#111111"/>
        <path d="M7.51873 3.27538C7.41526 3.11873 7.24647 3.01712 7.05959 2.99903C6.87272 2.98082 6.68756 3.04786 6.55573 3.18168C6.4425 3.29425 6.37651 3.44576 6.37097 3.60531C6.36556 3.765 6.42112 3.92059 6.52643 4.04069C6.63161 4.16079 6.77876 4.23628 6.93766 4.25172C7.09655 4.26716 7.25544 4.22149 7.38186 4.12397C7.50842 4.02644 7.59288 3.88444 7.61835 3.72673C7.64369 3.56915 7.60819 3.40775 7.51872 3.2754L7.51873 3.27538Z" fill="#111111"/>
        <path d="M6.9998 0.000202597C5.14336 0.000202597 3.36287 0.737792 2.05033 2.05053C0.737623 3.36324 0 5.1437 0 7C0 8.85657 0.737589 10.6369 2.05033 11.9498C3.36304 13.2625 5.14349 14 6.9998 14C8.85637 14 10.6367 13.2625 11.9496 11.9498C13.2623 10.6371 13.9998 8.85664 13.9998 7C13.9978 5.1442 13.2596 3.3651 11.9474 2.05256C10.635 0.740224 8.8558 0.00212844 6.99993 0L6.9998 0.000202597ZM6.9998 13.0002C5.4086 13.0002 3.88248 12.3681 2.75744 11.2429C1.63224 10.1177 1.0001 8.59157 1.0001 7.0002C1.0001 5.40901 1.63224 3.88288 2.75744 2.75785C3.88265 1.63265 5.40877 1.0005 6.9998 1.0005C8.59113 1.0005 10.1172 1.63265 11.2425 2.75785C12.3677 3.88305 12.9998 5.40918 12.9998 7.0002C12.998 8.59099 12.3653 10.1161 11.2405 11.2409C10.1157 12.3657 8.59065 12.9983 6.99987 13.0002L6.9998 13.0002Z" fill="#111111"/>
      </symbol>
      <symbol id="arrow-icon-left" viewBox="0 0 7 13">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70711 0.792893C7.09763 1.18342 7.09763 1.81658 6.70711 2.20711L2.41421 6.5L6.70711 10.7929C7.09763 11.1834 7.09763 11.8166 6.70711 12.2071C6.31658 12.5976 5.68342 12.5976 5.29289 12.2071L0.292893 7.20711C-0.0976313 6.81658 -0.0976312 6.18342 0.292893 5.79289L5.29289 0.792893C5.68342 0.402369 6.31658 0.402369 6.70711 0.792893Z" fill="#0055BF"/>
      </symbol>
      <symbol id="arrow-icon-ants-left" viewBox="0 0 7 13">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70711 0.792893C7.09763 1.18342 7.09763 1.81658 6.70711 2.20711L2.41421 6.5L6.70711 10.7929C7.09763 11.1834 7.09763 11.8166 6.70711 12.2071C6.31658 12.5976 5.68342 12.5976 5.29289 12.2071L0.292893 7.20711C-0.0976313 6.81658 -0.0976312 6.18342 0.292893 5.79289L5.29289 0.792893C5.68342 0.402369 6.31658 0.402369 6.70711 0.792893Z" fill="#F5FF78"/>
      </symbol>
      <symbol viewBox="0 0 16 16" id="cancel-icon">
        <path d="M8.00063 8.87687L10.5615 11.4378C10.6769 11.5532 10.822 11.6122 10.9966 11.6148C11.1713 11.6175 11.319 11.5585 11.4397 11.4378C11.5605 11.317 11.6208 11.1707 11.6208 10.9987C11.6208 10.8267 11.5605 10.6803 11.4397 10.5596L8.87882 7.99868L11.4397 5.43776C11.5551 5.32239 11.6141 5.17736 11.6168 5.00268C11.6195 4.82801 11.5605 4.68032 11.4397 4.5596C11.319 4.43886 11.1726 4.37849 11.0006 4.37849C10.8286 4.37849 10.6823 4.43886 10.5615 4.5596L8.00063 7.12049L5.43971 4.5596C5.32434 4.44421 5.17931 4.38518 5.00463 4.38251C4.82996 4.37983 4.68227 4.43886 4.56155 4.5596C4.44081 4.68032 4.38044 4.82668 4.38044 4.99868C4.38044 5.17068 4.44081 5.31704 4.56155 5.43776L7.12244 7.99868L4.56155 10.5596C4.44616 10.675 4.38713 10.82 4.38446 10.9947C4.38178 11.1693 4.44081 11.317 4.56155 11.4378C4.68227 11.5585 4.82863 11.6189 5.00063 11.6189C5.17263 11.6189 5.31899 11.5585 5.43971 11.4378L8.00063 8.87687ZM8.00203 15.9153C6.90708 15.9153 5.87789 15.7075 4.91444 15.292C3.95098 14.8764 3.11292 14.3125 2.40023 13.6001C1.68754 12.8877 1.12332 12.05 0.707589 11.087C0.291853 10.124 0.0839844 9.09502 0.0839844 8.00007C0.0839844 6.90513 0.291762 5.87593 0.707318 4.91249C1.12287 3.94903 1.68683 3.11096 2.39919 2.39828C3.11157 1.68559 3.94927 1.12137 4.9123 0.705636C5.87531 0.2899 6.90429 0.0820312 7.99924 0.0820312C9.09418 0.0820312 10.1234 0.28981 11.0868 0.705366C12.0503 1.12092 12.8883 1.68488 13.601 2.39724C14.3137 3.10962 14.8779 3.94732 15.2937 4.91035C15.7094 5.87336 15.9173 6.90234 15.9173 7.99728C15.9173 9.09223 15.7095 10.1214 15.2939 11.0849C14.8784 12.0483 14.3144 12.8864 13.6021 13.5991C12.8897 14.3118 12.052 14.876 11.089 15.2917C10.126 15.7075 9.09697 15.9153 8.00203 15.9153Z" fill="white"/>
      </symbol>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AllSvg',
};
</script>

<style scoped lang="scss">
#all-svg {
  display: none;
}
</style>
